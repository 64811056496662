<template>
    <div>
        <v-container class="py-10">
            <v-row>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date"
                        v-model="filters.from">
                    </v-text-field>
                </v-col>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date"
                        v-model="filters.to">
                    </v-text-field>
                </v-col>
                <v-col md="3">
                    <v-autocomplete :dark="$store.state.isDarkMode" v-model="filters.subject_id" :items="subjects"
                        :label="$t('subject')" item-text="name" item-value="id"
                        color="var(--bg-color--font)"></v-autocomplete>
                </v-col>
                <v-col md="3">
                    <v-autocomplete :dark="$store.state.isDarkMode" v-model="filters.unit_id" :items="units"
                        :label="$t('Unit')" item-text="name" item-value="id" color="var(--bg-color--font)"></v-autocomplete>
                </v-col>
                <v-col md="6">
                    <v-text-field dense :dark="$store.state.isDarkMode" :label="$t('Question Text')"
                        v-model="filters.name"></v-text-field>
                </v-col>
                <v-col md="3">
                    <v-text-field dense :dark="$store.state.isDarkMode" :label="$t('Answer')"
                        v-model="filters.answer"></v-text-field>
                </v-col>
                <v-col md="3"></v-col>
                <v-col md="3" class="mb-3 py-0">
                    <v-btn color="green" dark block @click="fetchData(1)">{{ $t('search') }}</v-btn>
                </v-col>
                <v-col md="3" class="mb-3 py-0">
                    <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
                </v-col>
                <v-col md="3" class="mb-3 py-0">
                    <ExportExcel :end-point="`lms/question/export`" :params="filters" />
                </v-col>
                <v-col md="12">
                    <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
                        @re-fetch-paginated-data="fetchData($event)" :enableDelete="false" :displaySettings="false">
                    </app-base-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import deleteItem from "../../../components/core/delete.vue";
import ExportExcel from "../../../components/core/ExportExcel.vue";
import axios from "axios";
export default {
    components: { deleteItem, ExportExcel },
    data() {
        return {
            tableOptions: {
                tableData: [],
                tableHeaders: ["id", "date", "Question Text", "subject", "Unit", "Correct Answer","Mark","Time"],
                accessTableData: [["id"], ["updated_at"], ["name"], ["subject"], ["unit", "name"], ["answer"],["mark"],["time"]],
                loading: false,
                tableMeta: {
                    total: 10,
                    page: 1,
                },
            },
            filters: {
                from: null,
                to: null,
                name: null,
                answer: null,
                unit_id: null,
                subject_id: null,
            },
        };
    },
    computed: {
        ...mapGetters({
            units: "subjects/getUnits",
            subjects: "subjects/getSubjects",
        }),
    },
    methods: {
        ...mapActions({
            fetchUnits: "subjects/fetchUnits",
            fetchSubjects: "subjects/fetchSubjects",
        }),
        async fetchData(page) {
            this.tableOptions.loading = true;
            const filterTemp = {};
            Object.keys(this.filters).forEach((key) => {
                if (this.filters[key]) filterTemp[key] = this.filters[key];
            });
            let params = {
                size: 10,
                page: page,
                ...filterTemp,
            };
            const response = await axios.get(`/lms/question`, { params });
            if (response.status == 200) {

                this.tableOptions.tableData = response.data.data.map((el) => {
                    return {
                        ...el,
                        subject: el.unit.subject.name,
                    };
                });
                this.tableOptions.tableMeta.total = response.data.meta.total;
                this.tableOptions.tableMeta.page = response.data.meta.current_page;
            }
            this.tableOptions.loading = false;
        },
        clearFilter() {         
            this.filters.from = undefined;
            this.filters.to = undefined;
            this.filters.name = undefined;
            this.filters.answer = undefined;
            this.filters.subject_id = undefined;
            this.filters.unit_id = undefined;
            this.fetchData(1);
        },
    },
    created() {
        this.fetchData(1);
        this.fetchSubjects();
        this.fetchUnits();
    },
};
</script>
